/*
 * SPDX-FileCopyrightText: 2022 Genome Research Ltd.
 *
 * SPDX-License-Identifier: MIT
 */

import { 
	Widgets,
    CentreContents,
    Container,
    Row,
    Col
} from '@tol/tol-ui'
import Assembly from '../components/Assembly';

const outline = (
    <Container>
    <Row>
        <Col sm={9}>
            <span>
                <h3>Mouse</h3>
                <div>
                    <p>The GRC’s mission is to provide the best possible reference assembly for mouse. In the mouse reference assembly, sequences in the primary assembly unit (chromosomes and unlocalized and unplaced scaffolds) are derived from the C57BL/6J strain. For several genomic regions that are variable between different strains, we provide alternate loci scaffolds comprised of clone-based sequences from a variety of other strains. Additionally, we are releasing regional fixes known as patches. This allows users who are interested in a specific locus to get an improved representation without affecting users who need chromosome coordinate stability. For more information please visit this <a href="https://www.ncbi.nlm.nih.gov/grc">link</a>.</p>
                </div>
                <br />
                <div>
                    <p>This browser focuses on the assembly sequence and displays alignments of different data types, enabling assessment of assembly correctness and quality.</p>
                </div>
            </span>
        </Col>
        <Col className="image-section" sm={3}>
            <img src="https://images.phylopic.org/images/6b2b98f6-f879-445f-9ac2-2c2563157025/raster/1024x542.png" className="silhouette" />
        </Col>
    </Row>
    </Container>
)

const GRCm39 = {
    "assembly_name": "GRCm39",
    "assembly_name_alt": "",
    "assembly_name_patch": "",
    "assembly_summary": "GRCm39",
    "assembly_strain":"GCA_000001635.9",
    "assembly_strain_link":"https://www.ncbi.nlm.nih.gov/datasets/genome/GCF_000001635.27/",
    "assembly_chr": "chr1",
    "assembly_chr_alt": "",
    "assembly_chr_patch": "",
    "assembly_type": "Mouse genome reference assembly (C57BL/6J strain)",
    "release_date": "2020-06-24",
    "release_type": "major",
    "assembly_units": "2",
    "bases_total": "2,728,222,451",
    "bases_nonn_total": "2,654,621,837",
    "assembly_n50": "106,145,001",
    "regions_total": "3",
    "regions_alternate": "0",
    "regions_fix": "0",
    "regions_novel": "0",
    "regions_par": "2",
    "jbrowse_tracks": '"GRCm39-ReferenceSequenceTrack","GRCm39_clone.bb","GRCm39_gencode_vM34_chr_patch_hapl_scaff_gene.gff.gz","mMusMuc1.1_mMusMuc1.1_GRCm39.chain","GRCm39_repeatMasker.bb","GRCm39_GRCm38_GRCm39_Alignment.paf","GRCm39_JiraIssue.bb","GRCm39_GRCm39.hic"',
    "jbrowse_tracks_alt": '',
    "jbrowse_tracks_patch": ''
}

const GRCm38p6 = {
    "assembly_name": "GRCm38.p6",
    "assembly_name_alt": "",
    "assembly_name_patch": "",
    "assembly_summary": "GRCm38.p6",
    "assembly_strain":"GCA_000001635.8",
    "assembly_strain_link":"https://www.ncbi.nlm.nih.gov/datasets/genome/GCF_000001635.26/",
    "assembly_chr": "chr1",
    "assembly_chr_alt": "",
    "assembly_chr_patch": "",
    "assembly_type": "Outdated mouse genome reference assembly (C57BL/6J strain)",
    "release_date": "2017-09-15",
    "release_type": "patch",
    "assembly_units": "17",
    "bases_total": "2,818,974,548",
    "bases_nonn_total": "2,739,538,976",
    "assembly_n50": "54,517,951",
    "regions_total": "129",
    "regions_alternate": "70",
    "regions_fix": "65",
    "regions_novel": "9",
    "regions_par": "2",
    "jbrowse_tracks": '"GRCm38.p6-ReferenceSequenceTrack","GRCm39_GRCm38_GRCm39_Alignment.paf"',
    "jbrowse_tracks_alt": '',
    "jbrowse_tracks_patch": ''
}

const mMusMuc1 = {
    "assembly_name": "mMusMuc1.1",
    "assembly_name_alt": "",
    "assembly_name_patch": "",
    "assembly_summary": "mMusMuc1.1",
    "assembly_strain":"GCA_949316315.1",
    "assembly_strain_link":"https://www.ncbi.nlm.nih.gov/datasets/genome/GCA_949316315.1/",
    "assembly_chr": "chr1",
    "assembly_chr_alt": "",
    "assembly_chr_patch": "",
    "assembly_type": "Assembly of the mMusMus1 sample",
    "release_date": "2023-04-08",
    "release_type": "",
    "assembly_units": "179",
    "bases_total": "2,770,968,735",
    "bases_nonn_total": "2,770,948,535",
    "assembly_n50": "132150484",
    "regions_total": "",
    "regions_alternate": "",
    "regions_fix": "",
    "regions_novel": "",
    "regions_par": "",
    "jbrowse_tracks": '"mMusMuc1.1-ReferenceSequenceTrack","mMusMuc1.1_mMusMuc1.1_GRCm39.chain","mMusMuc1.1_mMusMuc1_1.hic","mMusMuc1.1_repeatMasker.bb","mMusMuc1.1_stage2.gff.gz","mMusMuc1.1_m39_trans.bb"',
    "jbrowse_tracks_alt": '',
    "jbrowse_tracks_patch": ''
}

const assembly = (assembly_data: {[key: string]: string}) => (
    <Container>
        <Row>
            <Assembly 
                assembly_name={assembly_data.assembly_name}
                assembly_name_alt={assembly_data.assembly_name_alt}
                assembly_name_patch={assembly_data.assembly_name_patch}
                assembly_strain={assembly_data.assembly_strain}
                assembly_strain_link={assembly_data.assembly_strain_link}
                assembly_chr={assembly_data.assembly_chr}
                assembly_chr_alt={assembly_data.assembly_chr_alt}
                assembly_chr_patch={assembly_data.assembly_chr_patch}
                assembly_type={assembly_data.assembly_type}
                release_date={assembly_data.release_date}
                release_type={assembly_data.release_type}
                assembly_units={assembly_data.assembly_units}
                bases_total={assembly_data.bases_total}
                bases_nonn_total={assembly_data.bases_nonn_total}
                assembly_n50={assembly_data.assembly_n50}
                regions_total={assembly_data.regions_total}
                regions_alternate={assembly_data.regions_alternate}
                regions_fix={assembly_data.regions_fix}
                regions_novel={assembly_data.regions_novel}
                regions_par={assembly_data.regions_par}
                assembly_summary={assembly_data.assembly_summary}
                jbrowse_tracks={assembly_data.jbrowse_tracks}
                jbrowse_tracks_alt={assembly_data.jbrowse_tracks_alt}
                jbrowse_tracks_patch={assembly_data.jbrowse_tracks_patch}
            />
        </Row>
    </Container>
);

function Mouse() {
	
	return (
	<div className="mouse">
		<CentreContents>
			<Widgets components={[outline]}/>
			<Widgets components={[assembly(GRCm39)]}/>
			<Widgets components={[assembly(GRCm38p6)]}/>
            <Widgets components={[assembly(mMusMuc1)]}/>
		</CentreContents>
	</div>
	);
}
export default Mouse;