/*
 * SPDX-FileCopyrightText: 2022 Genome Research Ltd.
 *
 * SPDX-License-Identifier: MIT
 */

import { 
	Widgets,
    CentreContents,
    Container,
    Row,
    Col
} from '@tol/tol-ui'
import Assembly from '../components/Assembly';

const outline = (
    <Container>
        <Row>
            <Col sm={9}>
                <span>
                    <h3>Tasmanian Devil</h3>
                    <div>
                        <p>Assembly completed as part of study described in:</p>
                    </div>
                    <br/>
                    <div>
                        <p>Stammnitz, M. R., Gori, K., Kwon, Y. M., Harry, E., Martin, F. J., Billis, K., Cheng, Y., Baez-Ortega, A., Chow, W., Comte, S., Eggertsson, H., Fox, S., Hamede, R., Jones, M., Lazenby, B., Peck, S., Pye, R., Quail, M. A., Swift, K., Wang, J., Wood, J., Howe, K., Stratton, M. R., Ning, Z., Murchison, E. P. (2023). The evolution of two transmissible cancers in Tasmanian devils. Science, 380(6642), 283–293.</p>
                    </div>
                    <br/>
                    <div>
                        <a href="https://www.science.org/doi/full/10.1126/science.abq6453">DOI: 10.1126/science.abq6453</a>
                    </div>
                </span>
            </Col>
            <Col className="image-section" sm={3}>
            <img src="https://images.phylopic.org/images/58cc56c2-5a36-4031-be9f-28c86f77963c/raster/1024x552.png" className="silhouette" />
            </Col>
        </Row>
    </Container>
)

let mSarHar1 = {
    "assembly_name": "mSarHar1.11",
    "assembly_name_alt": "",
    "assembly_name_patch": "",
    "assembly_summary": "mSarHar1.11",
    "assembly_strain":"GCA_902635505.1",
    "assembly_strain_link":"https://www.ncbi.nlm.nih.gov/datasets/genome/GCF_902635505.1/",
    "assembly_chr": "Super_scaffold_1",
    "assembly_chr_alt": "",
    "assembly_chr_patch": "",
    "assembly_type": "Genome assembly of a Sarcophilus harrisii female",
    "release_date": "",
    "release_type": "",
    "assembly_units": "106",
    "bases_total": "3,086,674,442",
    "bases_nonn_total": "3,086,627,671",
    "assembly_n50": "611,347,268",
    "regions_total": "",
    "regions_alternat": "",
    "regions_fix": "",
    "regions_novel": "",
    "regions_par": "",
    "jbrowse_tracks": '"mSarHar1.11-ReferenceSequenceTrack","mSarHar1.11_mSarHar1.11_refseq_gene.gff.gz","mSarHar1.11_repeatMasker.bb"',
    "jbrowse_tracks_alt": '',
    "jbrowse_tracks_patch": ''
}

const assembly = (assembly_data: {[key: string]: string}) => (
    <Container>
        <Row>
            <Assembly 
                assembly_name={assembly_data.assembly_name}
                assembly_name_alt={assembly_data.assembly_name_alt}
                assembly_name_patch={assembly_data.assembly_name_patch}
                assembly_strain={assembly_data.assembly_strain}
                assembly_strain_link={assembly_data.assembly_strain_link}
                assembly_chr={assembly_data.assembly_chr}
                assembly_chr_alt={assembly_data.assembly_chr_alt}
                assembly_chr_patch={assembly_data.assembly_chr_patch}
                assembly_type={assembly_data.assembly_type}
                release_date={assembly_data.release_date}
                release_type={assembly_data.release_type}
                assembly_units={assembly_data.assembly_units}
                bases_total={assembly_data.bases_total}
                bases_nonn_total={assembly_data.bases_nonn_total}
                assembly_n50={assembly_data.assembly_n50}
                regions_total={assembly_data.regions_total}
                regions_alternate={assembly_data.regions_alternate}
                regions_fix={assembly_data.regions_fix}
                regions_novel={assembly_data.regions_novel}
                regions_par={assembly_data.regions_par}
                assembly_summary={assembly_data.assembly_summary}
                jbrowse_tracks={assembly_data.jbrowse_tracks}
                jbrowse_tracks_alt={assembly_data.jbrowse_tracks_alt}
                jbrowse_tracks_patch={assembly_data.jbrowse_tracks_patch}
            />
        </Row>
    </Container>
);


function TasmanianDevil() {
	
	return (
	<div className="tasmaniandevil">
		<CentreContents>
			<Widgets components={[outline]}/>
			<Widgets components={[assembly(mSarHar1)]}/>
		</CentreContents>
	</div>
	);
}
export default TasmanianDevil;