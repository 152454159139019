/*
SPDX-FileCopyrightText: 2023 Genome Research Ltd.

SPDX-License-Identifier: MIT
*/

import ReactDOM from 'react-dom';
import { Home, Human, Mouse, Rat, TasmanianDevil, Zebrafish } from './pages';
import reportWebVitals from './reportWebVitals';
import { TolApp, Page } from '@tol/tol-ui'
import Logo from './assets/logo.png';
import './scss/styling.scss';

const human: Page = {
  name: "Human",
  authRequired: false,
  adminOnly: false,
  uiElement: <Human />
}

const mouse: Page = {
  name: "Mouse",
  authRequired: false,
  adminOnly: false,
  uiElement: <Mouse />
}

const rat: Page = {
  name: "Rat",
  authRequired: false,
  adminOnly: false,
  uiElement: <Rat />
}

const zebrafish: Page = {
  name: "Zebrafish",
  authRequired: false,
  adminOnly: false,
  uiElement: <Zebrafish />
}

const tasmaniandevil: Page = {
  name: "Tasmanian Devil",
  authRequired: false,
  adminOnly: false,
  uiElement: <TasmanianDevil />
}



ReactDOM.render(
  <TolApp
    brand={
      <img
        src={Logo}
        alt="GRC Logo"
        style={{height:40}}
      />
    }
    homePage={<Home />}
    pages={[
      human,mouse,rat,zebrafish,tasmaniandevil
    ]}
    login={false}
  />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
