/*
 * SPDX-FileCopyrightText: 2022 Genome Research Ltd.
 *
 * SPDX-License-Identifier: MIT
 */

import { 
	Widgets,
    CentreContents,
    Container,
    Row,
	Col
} from '@tol/tol-ui'
import Assembly from '../components/Assembly';

function Human() {

	const outline = (
		<Container>
			<Row>
				<Col sm={9}>
					<span>
						<h3>Human</h3>
						<div>
							<p>The GRC’s mission is to provide the best possible reference assembly for human. We do this by generating multiple representations (alternate loci) for regions that are too complex to be represented by a single path. Additionally, we are releasing regional fixes known as patches. This allows users who are interested in a specific locus to get an improved representation without affecting users who need chromosome coordinate stability. For more information please visit this <a href="https://www.ncbi.nlm.nih.gov/grc">link</a>.</p>
						</div>
						<br />
						<div>
							<p>This browser focuses on the assembly sequence and displays alignments of different data types, enabling assessment of assembly correctness and quality. </p>
						</div>
					</span>
				</Col>
				<Col className="image-section" sm={3}>
					<img src="https://images.phylopic.org/images/9c6af553-390c-4bdd-baeb-6992cbc540b1/raster/346x1024.png" className="silhouette" />
				</Col>
			</Row>
		</Container>
	)
	
	let CHM13T2Tv2 = {
		"assembly_name": "CHM13T2Tv2.0",
		"assembly_name_alt": "",
		"assembly_name_patch": "",
		"assembly_summary": "T2T-CHM13v2.0",
		"assembly_strain":"GCA_009914755.4",
		"assembly_strain_link":"https://www.ncbi.nlm.nih.gov/datasets/genome/GCF_009914755.1/",
		"assembly_chr": "chr1",
		"assembly_chr_alt": "",
		"assembly_chr_patch": "",
		"assembly_type": "T2T assembly of haploid cell line (CHM13htert) derived from a hydatidiform mole",
		"release_date": "",
		"release_type": "",
		"assembly_units": "25",
		"bases_total": "3,117,292,070",
		"bases_nonn_total": "3,117,292,070",
		"assembly_n50": "150,617,247",
		"regions_total": "",
		"regions_alternate": "",
		"regions_fix": "",
		"regions_novel": "",
		"regions_par": "",
		"jbrowse_tracks": '"CHM13T2Tv2.0-ReferenceSequenceTrack","CHM13T2Tv2.0_censat.bed.gz","CHM13T2Tv2.0_cytobands_allchrs.bed.gz","CHM13T2Tv2.0_refseq_gene.gff.gz","GRCh38.p14_CHM13T2Tv2.0_alignment_.paf","CHM13T2Tv2.0_CHM13T2Tv2.0.hic","CHM13T2Tv2.0_chm13_Saphyr_DLE1_optical_map.bb","CHM13T2Tv2.0_BSPQI.bigBed","CHM13T2Tv2.0_BSSSI.bigBed","CHM13T2Tv2.0_DLE1.bigBed"',
		"jbrowse_tracks_alt": '',
		"jbrowse_tracks_patch": ''
	}
	
	
	let GRCh38p14 = {
		"assembly_name": "GRCh38.p14",
		"assembly_name_alt": "GRCh38p14_alt",
		"assembly_name_patch": "GRCh38p14_patch",
		"assembly_summary": "Human genome reference assembly",
		"assembly_strain":"GCA_000001405.29",
		"assembly_strain_link":"https://www.ncbi.nlm.nih.gov/datasets/genome/GCF_000001405.40/",
		"assembly_chr": "chr1",
		"assembly_chr_alt": "chr11_JH159136v1_alt",
		"assembly_chr_patch": "chr9_MU273365v1_fix",
		"assembly_type": "Human genome reference assembly",
		"release_date": "2022-02-03",
		"release_type": "patch",
		"assembly_units": "38",
		"bases_total": "3,298,912,062",
		"bases_nonn_total": "3,137,300,923",
		"assembly_n50": "67,794,873",
		"regions_total": "434",
		"regions_alternate": "178",
		"regions_fix": "164",
		"regions_novel": "90",
		"regions_par": "4",
		"jbrowse_tracks": '"GRCh38.p14-ReferenceSequenceTrack","GRCh38.p14_Gennomic_regions.bed.gz","GRCh38.p14_CHM13T2Tv2.0_alignment_.paf","GRCh38.p14_gencode.v44.chr_patch_hapl_scaff.annotation.gff.gz","PUR_Father_BssSI_HG00731","GRCh38.p14_alts.paf","GRCh38.p14_patches.paf","GRCh38.p14_clone.bb","GRCh38.p14_repeats_RepeatMasker.bb","GRCh38.p14_NA12878_MtSinai_BSPQI_optical_map.bb","GRCh38.p14_YRI_Daughter_BssSI_GM19240.bb","GRCh38.p14_YRI_Mother_BssSI_GM19238.bb","GRCh38.p14_YRI_Father_BssSI_GM19239_ypsilon15.bb","GRCh38.p14_YRI_Mother_BspQI-CP_GM19238.bb","GRCh38.p14_Ashkenazi_father_BspQI_optical_mapping.bb","GRCh38.p14_Ashkenazi_mother_BspQI_optical_mapping.bb","GRCh38.p14_Ashkenazi_son_BspQI_optical_mapping.bb","GRCh38.p14_ChineseTrio_HG005_NA24631_son_BspQI_optical_mapping.bb","GRCh38.p14_ashkenazi_mother_BspQI_optical_map.bb","GRCh38.p14_BSPQI.bigBed","GRCh38.p14_BSSSI.bigBed","GRCh38.p14_DLE1.bigBed","GRCh38.p14_CHS_Daughter_BspQI-CP_HG00514.bb","GRCh38.p14_CHS_Daughter_BssSI_HG00514.bb","GRCh38.p14_CHS_Mother_BssSI_HG00513.bb","GRCh38.p14_CHS_Mother_BspQI-CP_HG00513.bb","GRCh38.p14_CHS_Father_BssSI_HG00512.bb","GRCh38.p14_CHS_Father_BspQI-CP_HG00512.bb","GRCh38.p14_PUR_Father_BspQI-CP_HG00731.bb","GRCh38.p14_PUR_Mother_BspQI-CP_HG00732.bb","GRCh38.p14_PUR_Mother_BssSI_HG00732.bb","GRCh38.p14_PUR_Daughter_BssSI_HG00733_ypsilon15.bb","GRCh38.p14_PUR_Daughter_BspQI-CP_HG00733.bb","GRCh38.p14_JiraIssue.bb"',
		"jbrowse_tracks_alt": '"GRCh38p14_alt-ReferenceSequenceTrack","GRCh38.p14_alts.paf"',
		"jbrowse_tracks_patch": '"GRCh38p14_patch-ReferenceSequenceTrack","GRCh38.p14_patches.paf"'
	}

	const assembly = (assembly_data: {[key: string]: string}) => (
		<Container>
			<Row>
				<Assembly 
					assembly_name={assembly_data.assembly_name}
					assembly_name_alt={assembly_data.assembly_name_alt}
					assembly_name_patch={assembly_data.assembly_name_patch}
					assembly_strain={assembly_data.assembly_strain}
					assembly_strain_link={assembly_data.assembly_strain_link}
					assembly_chr={assembly_data.assembly_chr}
					assembly_chr_alt={assembly_data.assembly_chr_alt}
					assembly_chr_patch={assembly_data.assembly_chr_patch}
					assembly_type={assembly_data.assembly_type}
					release_date={assembly_data.release_date}
					release_type={assembly_data.release_type}
					assembly_units={assembly_data.assembly_units}
					bases_total={assembly_data.bases_total}
					bases_nonn_total={assembly_data.bases_nonn_total}
					assembly_n50={assembly_data.assembly_n50}
					regions_total={assembly_data.regions_total}
					regions_alternate={assembly_data.regions_alternate}
					regions_fix={assembly_data.regions_fix}
					regions_novel={assembly_data.regions_novel}
					regions_par={assembly_data.regions_par}
					assembly_summary={assembly_data.assembly_summary}
					jbrowse_tracks={assembly_data.jbrowse_tracks}
					jbrowse_tracks_alt={assembly_data.jbrowse_tracks_alt}
					jbrowse_tracks_patch={assembly_data.jbrowse_tracks_patch}
				/>
			</Row>
		</Container>
	);
	
	return (
	<div className="human">
		<CentreContents>
			<Widgets components={[outline]}/>
			<Widgets components={[assembly(CHM13T2Tv2)]}/>
			<Widgets components={[assembly(GRCh38p14)]}/>
		</CentreContents>
	</div>
	);
}
export default Human;