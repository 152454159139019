/*
 * SPDX-FileCopyrightText: 2022 Genome Research Ltd.
 *
 * SPDX-License-Identifier: MIT
 */

import { 
	Widgets,
    CentreContents,
    Container,
    Row,
    Col
} from '@tol/tol-ui'
import Assembly from '../components/Assembly';

const outline = (
        <Container>
        <Row>
            <Col sm={9}>
                <span>
                    <h3>Rat</h3>
                    <div>
                        <p>GRCr8 was generated by Dr. Peter Doris (University of Texas Health Science Center at Houston) with colleagues Theodore Kalbfleisch (University of Kentucky) and Melissa Smith (University of Louisville) in the NHGRI-funded "Inbred Rat Genomes Project". It is a new de novo assembly that includes some contigs from the PacBio CLR reads produced for mRatBN7.2 (GCA_015227675.2).</p>
                    </div>
                    <br />
                    <div>
                        <p>This browser focuses on the assembly sequence and displays alignments of different data types, enabling assessment of assembly correctness and quality.</p>
                    </div>
                </span>
            </Col>
            <Col className="image-section" sm={3}>
                <img src="https://images.phylopic.org/images/828a8d15-6aa9-41ab-85a3-e9e06c0f1945/raster/1536x462.png" className="silhouette" />
            </Col>
        </Row>
        </Container>
)

const GRCr8 = {
    "assembly_name": "GRCr8",
    "assembly_name_alt": "",
    "assembly_name_patch": "",
    "assembly_summary": "GRCr8",
    "assembly_strain":"GCA_036323735.1",
    "assembly_strain_link":"https://www.ncbi.nlm.nih.gov/datasets/genome/GCF_036323735.1/",
    "assembly_chr": "chr1",
    "assembly_chr_alt": "",
    "assembly_chr_patch": "",
    "assembly_type": "Reference genome assembly of a Brown Norway (BN) male",
    "release_date": "2024-01-31",
    "release_type": "major",
    "assembly_units": "2",
    "bases_total": "2,849,597,507",
    "bases_nonn_total": "2,845,466,930",
    "assembly_n50": "137,014,596",
    "regions_total": "0",
    "regions_alternate": "0",
    "regions_fix": "0",
    "regions_novel": "0",
    "regions_par": "0",
    "jbrowse_tracks": '"GRCr8-ReferenceSequenceTrack","mRatBN7.2_mRatBN7.2_GRCr8_alignment.paf","GRCr8_GRCr8.hic"',
    "jbrowse_tracks_alt": '',
    "jbrowse_tracks_patch": ''
}

const mRatBN7 =  {
    "assembly_name": "mRatBN7.2",
    "assembly_name_alt": "",
    "assembly_name_patch": "",
    "assembly_summary": "mRatBN7.2",
    "assembly_strain":"GCA_015227675.2",
    "assembly_strain_link":"https://www.ncbi.nlm.nih.gov/datasets/genome/GCF_015227675.2/",
    "assembly_chr": "chr1",
    "assembly_chr_alt": "",
    "assembly_chr_patch": "",
    "assembly_type": "Outdated reference genome assembly of a Brown Norway (BN) male",
    "release_date": "2020-11-04",
    "release_type": "major",
    "assembly_units": "2",
    "bases_total": "2,647,915,728",
    "bases_nonn_total": "2,626,580,772",
    "assembly_n50": "135,012,528",
    "regions_total": "0",
    "regions_alternate": "0",
    "regions_fix": "0",
    "regions_novel": "0",
    "regions_par": "0",
    "jbrowse_tracks": '"mRatBN7.2-ReferenceSequenceTrack","mRatBN7.2_mRatBN7.2_refseq.gff3.gz","mRatBN7.2_repeatMasker.bb","mRatBN7.2_mRatBN7.2_refseq_sorted.gff.gz","mRatBN7.2_mRatBN7.2_GRCr8_alignment.paf","mRatBN7.2_JiraIssue.bb","mRatBN7.2_DLE1.bigBed","mRatBN7.2_mRatBN7.2_DLE1_optical_map.bb"',
    "jbrowse_tracks_alt": '',
    "jbrowse_tracks_patch": ''
}

const assembly = (assembly_data: {[key: string]: string}) => (
    <Container>
        <Row>
            <Assembly 
                assembly_name={assembly_data.assembly_name}
                assembly_name_alt={assembly_data.assembly_name_alt}
                assembly_name_patch={assembly_data.assembly_name_patch}
                assembly_strain={assembly_data.assembly_strain}
                assembly_strain_link={assembly_data.assembly_strain_link}
                assembly_chr={assembly_data.assembly_chr}
                assembly_chr_alt={assembly_data.assembly_chr_alt}
                assembly_chr_patch={assembly_data.assembly_chr_patch}
                assembly_type={assembly_data.assembly_type}
                release_date={assembly_data.release_date}
                release_type={assembly_data.release_type}
                assembly_units={assembly_data.assembly_units}
                bases_total={assembly_data.bases_total}
                bases_nonn_total={assembly_data.bases_nonn_total}
                assembly_n50={assembly_data.assembly_n50}
                regions_total={assembly_data.regions_total}
                regions_alternate={assembly_data.regions_alternate}
                regions_fix={assembly_data.regions_fix}
                regions_novel={assembly_data.regions_novel}
                regions_par={assembly_data.regions_par}
                assembly_summary={assembly_data.assembly_summary}
                jbrowse_tracks={assembly_data.jbrowse_tracks}
                jbrowse_tracks_alt={assembly_data.jbrowse_tracks_alt}
                jbrowse_tracks_patch={assembly_data.jbrowse_tracks_patch}
            />
        </Row>
    </Container>
);

function Rat() {
	
	return (
	<div className="rat">
		<CentreContents>
			<Widgets components={[outline]}/>
            <Widgets components={[assembly(GRCr8)]}/>
			<Widgets components={[assembly(mRatBN7)]}/>
		</CentreContents>
	</div>
	);
}
export default Rat;