/*
 * SPDX-FileCopyrightText: 2022 Genome Research Ltd.
 *
 * SPDX-License-Identifier: MIT
 */

import { 
	Widgets,
    CentreContents,
    Container,
    Row,
    Col
} from '@tol/tol-ui'
import Assembly from '../components/Assembly';

const outline = (
    <Container>
    <Row>
        <Col sm={9}>
            <span>
                <h3>Zebrafish</h3>
                <div>
                <p>GRCz11, the zebrafish reference genome assembly,  features alternate loci scaffolds (ALT_REF_LOCI) for representations of variant sequences. The alignments of the alternate loci scaffolds to the primary chromosomal path are included in the GRCz11 assembly release to provide the chromosome context for these alternate sequences.</p>
                </div>
            </span>
        </Col>
        <Col className="image-section" sm={3}>
            <img src="https://images.phylopic.org/images/5155f7c4-4609-44ed-a5e9-b3ba64b574e3/raster/374x120.png" className="silhouette" />
        </Col>
    </Row>
    </Container>
)

let GRCz11 = {
    "assembly_name": "GRCz11",
    "assembly_name_alt": "GRCz11_alt",
    "assembly_name_patch": "",
    "assembly_summary": "GRCz11",
    "assembly_strain":"GCA_000002035.4",
    "assembly_strain_link":"https://www.ncbi.nlm.nih.gov/datasets/genome/GCF_000002035.6/",
    "assembly_chr": "chr1",
    "assembly_chr_alt": "chr1_KZ114997v1_alt",
    "assembly_chr_patch": "",
    "assembly_type": "Zebrafish genome reference assembly (Tuebingen strain)",
    "release_date": "2017-05-09",
    "release_type": "major",
    "assembly_units": "5",
    "bases_total": "1,373,454,788",
    "bases_nonn_total": "1,368,763,551",
    "assembly_n50": "54,304,671",
    "regions_total": "607",
    "regions_alternate": "607",
    "regions_fix": "",
    "regions_novel": "",
    "regions_par": "",
    "jbrowse_tracks": '"GRCz11-ReferenceSequenceTrack","GRCz11_JiraIssue.bb","GRCz11_repeatMasker.bb","GRCz11_RefSeq.gff.gz","GRCz11_GRCz11_fDanRer4.1_alignment.paf","GRCz11_clone.bb","GRCz11_GRCz11_alt_alignment.paf","GRCz11_BSPQI.bigBed","GRCz11_BSPQI_optical_map.bb","GRCz11_GRCz11.hic"',
    "jbrowse_tracks_alt": '"GRCz11_alt-ReferenceSequenceTrack","GRCz11_GRCz11_alt_alignment.paf"',
    "jbrowse_tracks_patch": ''
}

const fDanRer4 = {
    "assembly_name": "fDanRer4.1",
    "assembly_name_alt": "",
    "assembly_name_patch": "",
    "assembly_summary": "fDanRer4.1",
    "assembly_strain":"GCA_944039275.1",
    "assembly_strain_link":"https://www.ncbi.nlm.nih.gov/datasets/genome/GCA_944039275.1/",
    "assembly_chr": "SUPER_1",
    "assembly_chr_alt": "",
    "assembly_chr_patch": "",
    "assembly_type": "Assembly of a single zebrafish sample (fDanRer4)",
    "release_date": "",
    "release_type": "",
    "assembly_units": "73",
    "bases_total": "1,413,675,870",
    "bases_nonn_total": "1,413,553,475",
    "assembly_n50": "58,626,443",
    "regions_total": "",
    "regions_alternate": "",
    "regions_fix": "",
    "regions_novel": "",
    "regions_par": "",
    "jbrowse_tracks": '"fDanRer4.1-ReferenceSequenceTrack","GRCz11_GRCz11_fDanRer4.1_alignment.paf"',
    "jbrowse_tracks_alt": '',
    "jbrowse_tracks_patch": ''
}

const assembly = (assembly_data: {[key: string]: string}) => (
    <Container>
        <Row>
            <Assembly 
                assembly_name={assembly_data.assembly_name}
                assembly_name_alt={assembly_data.assembly_name_alt}
                assembly_name_patch={assembly_data.assembly_name_patch}
                assembly_strain={assembly_data.assembly_strain}
                assembly_strain_link={assembly_data.assembly_strain}
                assembly_chr={assembly_data.assembly_chr}
                assembly_chr_alt={assembly_data.assembly_chr_alt}
                assembly_chr_patch={assembly_data.assembly_chr_patch}
                assembly_type={assembly_data.assembly_type}
                release_date={assembly_data.release_date}
                release_type={assembly_data.release_type}
                assembly_units={assembly_data.assembly_units}
                bases_total={assembly_data.bases_total}
                bases_nonn_total={assembly_data.bases_nonn_total}
                assembly_n50={assembly_data.assembly_n50}
                regions_total={assembly_data.regions_total}
                regions_alternate={assembly_data.regions_alternate}
                regions_fix={assembly_data.regions_fix}
                regions_novel={assembly_data.regions_novel}
                regions_par={assembly_data.regions_par}
                assembly_summary={assembly_data.assembly_summary}
                jbrowse_tracks={assembly_data.jbrowse_tracks}
                jbrowse_tracks_alt={assembly_data.jbrowse_tracks_alt}
                jbrowse_tracks_patch={assembly_data.jbrowse_tracks_patch}
            />
        </Row>
    </Container>
);

function Zebrafish() {
	
	return (
	<div className="zebrafish">
		<CentreContents>
			<Widgets components={[outline]}/>
			<Widgets components={[assembly(GRCz11)]}/>
            <Widgets components={[assembly(fDanRer4)]}/>
		</CentreContents>
	</div>
	);
}
export default Zebrafish;

